import React from 'react';
import {
  Center, Heading, Stack, Text,
} from '@chakra-ui/react';

const Contato = () => (
  <Center h="100vh" w="100vw">
    <Stack>
      <Heading as="h1">Contato</Heading>
      <Text>
        Qualquer dúvida, sugestão ou reclamação entre em contato pelo página
        oficial no Facebook ou envie um e-mail diretamente para um dos nossos
        coordenadores gerais.
      </Text>
    </Stack>
  </Center>
);

export default Contato;
